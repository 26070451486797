//Colores
$color-base:#645454;
$color-secundario:#333;
$blanco: #fff;

$blanco-hover:#EAEAEC;
$negro:#333;
$hover-secundario:#B29718;
$cafe-base:#B4A274;
$cafe-fuerte:#5f4323;




//Mixins
@mixin focus-accesible
{
  border: solid 2px $negro;
}