@media only screen and (max-width: 1160px) {
   .search
{
   
    right: 400%;
   

}
}

@media only screen and (max-width: 1023px)
 {

.logo {
    padding: 0.5rem;
}

header
{
    padding: 0;
}
.top-bar, .top-bar ul
{
    background-color: $blanco;
}
  .menu-logo
{


    .logo
    {
         @include grid-column(8); 
        // @include grid-column-position(center);
         position: relative;
         margin-top: 43px;
         
    }

    nav{
         @include grid-column(12); 
         margin-top: 0;
         margin-left: 0;
         position: absolute;
         background-color: $blanco;
         border-bottom: solid 1px #333;
         z-index: 100;
         


         
    }

    .buscador
    {
        @include grid-column(4);
        position:relative;
    }

}

.menu > li > a
{
    color: $negro;
}
//Buscador
.search
{
    display: block;
    margin-top: 11rem;
    position: relative;
    right: 0;
}
.mostrar-caja{
    display: none;
}


.title-bar-title {
    display: inline-block;
}

    .input-group
    {
        width: 100%;
    }

 }

@media only screen and (max-width: 700px) {

     .menu-logo
    {


        .logo
        {
            @include grid-column(4); 
            
            
        }
    
  

    .buscador
    {
        @include grid-column(8);
        position:relative;
    }
    }


}
