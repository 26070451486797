header{
    background-color: $color-base;
    padding: 1rem;
}
 
 .intro
 {
      background-color: #2F2F2F;
      box-shadow: 2px -1px 1px 1px rgba(0, 0, 0, 0.5);
 }
 .banner,.slider,.secciones
{
    @include grid-row();
   

}

.slider
{
    margin-top: 1.3rem; 
}
.contenedor
{
    @include grid-row();
    margin-top: 2.5rem;
}
.menu-logo
{
    @include grid-row();
    

    .logo
    {
         @include grid-column(4); 
        
    }

    nav{
         @include grid-column(8); 

    }

 
}
// Menu 
.dropdown.menu > li.is-dropdown-submenu-parent > a::after
{
    border-color:$blanco transparent transparent;
}
.menu > li > a
{
    color:$blanco;
}

.menu > li > a:hover
{
    background: $blanco-hover;
    color: $negro;
} 


.submenu a 
{
   

    &:hover{
        background-color: $negro !important;
        color:$blanco !important;
    }
} 

button.menu-icono {
    font-size: 30px;
    border: solid 1px #333;
    border-radius: 5px;
    color: #333;
    margin: 5px;
}
.top-bar .top-bar-left, .top-bar .top-bar-right
{
   width: 100% !important;
}
//Footer
footer {
    background-color: $color-base;
    text-align: center;
    color: $blanco;
    padding: 5px;

    h6{
        margin-bottom: 0;
    }
}

    ul.menu.dropdown {
  display: block !important;
}