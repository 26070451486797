//Fonts
@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?23026463');
  src: url('../font/fontello.eot?23026463#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?23026463') format('woff2'),
       url('../font/fontello.woff?23026463') format('woff'),
       url('../font/fontello.ttf?23026463') format('truetype'),
       url('../font/fontello.svg?23026463#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

 .menu-icono:before,.siguiente a:after,.anterior a:before,.listado a:before,.icono-busqueda:before, [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-search:before,.icono-busqueda:before { content: '\e800'; } 
.menu-icono:before { content: '\f0c9'; } 


.icon-th-list:before,.listado a:before { content: '\e801'; } /* '' */
.icon-left-open:before,.anterior a:before { content: '\e802'; } /* '' */
.icon-right-open:before,.siguiente a:after { content: '\e803'; } /* '' */
.icon-pause:before { content: '\e804'; } /* '' */