.carrusel
{
     @include grid-column(8); 
}
.eventos
{
    @include grid-column(4); 
}

.anterior:focus,.siguiente:focus {
   @include focus-accesible;
}

.siguiente{
    text-align: right;
}
.anterior,.siguiente
{
    @include grid-column(3,0);

    a{
        padding: 8px;
    }
}

.listado
{
    @include grid-column(4,0);
    text-align: center
}

.owl-banner a:focus img{
      @include focus-accesible;
}

.pausa
{
     @include grid-column(1,0);
}