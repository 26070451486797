//miconfiguracion 
@import 'settings';

// Estilos de foundation
@import '../bower_components/foundation-sites/scss/foundation';
@import '../bower_components/foundation-sites/scss/global';



//Estilos de foundation
@include foundation-global-styles;
@include foundation-typography;
@include foundation-top-bar;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu; 
@include foundation-forms;
//@include foundation-button;


//Mis estilos
@import 'layout';
@import 'carrusel';
@import 'mediaquerys';
@import 'fonts';


   
.btn
{
    @include button;
}